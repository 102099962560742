import { useState, useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import './Devlog';
import qualys from '../images/qualys.jpg';
import databreach from '../images/databreach.jpg';
import mysql from '../images/mysql.JPG';
import tableau from '../images/tableaudashboard.JPG';
import sql from '../images/sql.JPG';
import shinobi from '../images/shinobi.JPG';
import openmediavault from '../images/openmediavault.JPG';
import critellis from '../images/critellis.JPG';
import paradigm from '../images/paradigm.JPG';
import mail from '../mail.svg';
import hamburger from '../hamburger.svg';
import linked from '../linked.svg';
import github from '../github.svg';
import logo from '../logo.svg';

function Home() {
    
  const [showMainProjects, setShowMainProjects] = useState(false);
  const [showMainMessage, setShowMainMessage] = useState(false);
  const [showGoTopButton, setShowGoTopButton] = useState(false);

  const handleHamburgerClick = () => {
      setShowMainProjects(!showMainProjects);
      setShowMainMessage(!showMainMessage);
  };
  
  


  const [pageVisibility, setPageVisibility] = useState({
    home: true,
    webdev: false,
    sql: false,
    infrastructure: false,
    pentesting: false,
    python: false,
    raspberry: false
  });

  function togglePortfolio(page) {
    setPageVisibility(prevState => {
      const updatedVisibility = {
        home: true,
        webdev: false,
        sql: false,
        infrastructure: false,
        pentesting: false,
        python: false,
        raspberry: false
      };

      if (prevState[page]) {
        updatedVisibility.home = true;
      } else {
        updatedVisibility.home = false;
        updatedVisibility[page] = true;
      }

      return updatedVisibility;
    });
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleScroll() {
    if (window.scrollY > 100) {
      // Show or hide the button based on the scroll position
      setShowGoTopButton(true);
    } else {
      setShowGoTopButton(false);
    }
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  function handleClick(page) {
    togglePortfolio(page);
    scrollToTop();
    if (showMainProjects) {
      setShowMainProjects(false);
      setShowMainMessage(false);
    }
  }
  


  return (
    <>
      <div className="main-container">
        <div className="main-container-inner">
          <div className="inner-container">
            <header className="logoheader">
              <div className="logo">
                <img className="logosvg" onClick={() => handleClick('home')} src={logo} alt="logo" />
              </div>
              
              
              <div className ="hamburger-menu"> <img className="hamburgermenu" onClick={handleHamburgerClick} src={hamburger} alt="menu" /> </div>
              <div className="socials">
                <ul>
                  <li className="email">
                    <a href="mailto:nate@natemcaree.com">
                      <img className="emailsvg" src={mail} alt="email" />
                    </a>
                  </li>
                  <li className="github">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://github.com/natemcaree"
                    >
                      <img
                        className="githubsvg"
                        src={github}
                        alt="github"
                      ></img>
                    </a>
                  </li>
                  <li className="linkedin">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/in/nathan-mcaree/"
                    >
                      <img
                        className="linkedsvg"
                        src={linked}
                        alt="linkedin"
                      ></img>
                    </a>
                  </li>
                </ul>
              </div>
            </header>
            
            <main className="projects-overview">
            <div className={`main-projects${showMainProjects ? '-show' : ''}`}>
             
             <div className="project-wrapper">
               <div className="project-list">
               
                 <header>Projects</header>
                 
                 <div
                   className="project-portfolio"
                   onClick={() => handleClick('python')}
                 >
                   <span className="portfolio-link">
                     {pageVisibility.python ? 'cd /Home' : 'cd /TwitterBot'}
                   </span>
                 </div>
                 <div
                   className="project-portfolio"
                   onClick={() => handleClick('raspberry')}
                 >
                   <span className="portfolio-link">
                     {pageVisibility.raspberry ? 'cd /Home' : 'cd /NAS and RTSP'}
                   </span>
                 </div>
                 
                 <div
                   className="project-portfolio"
                   onClick={() => handleClick('infrastructure')}
                 >
                   <span className="portfolio-link">
                     {pageVisibility.infrastructure ? 'cd /Home' : 'cd /Infrastructure'}
                   </span>
                 </div>

                

                 <div
                   className="project-portfolio"
                   onClick={() => handleClick('webdev')}
                 >
                   <span className="portfolio-link">
                     {pageVisibility.webdev ? 'cd /Home' : 'cd /Web Dev'}
                   </span>
                 </div>

                 <div
                   className="project-portfolio"
                   onClick={() => handleClick('sql')}
                 >
                   <span className="portfolio-link">
                     {pageVisibility.sql ? 'cd /Home' : 'cd /SQL, Tableau'}
                   </span>
                 </div>

               </div>
             </div>
              {showGoTopButton && (
              <div className="extras">
                <button className="goTop" onClick={scrollToTop}></button>
              </div>
            )}
           </div>
              <div className={`main-message${showMainMessage ? '-hide' : ''}`}>
              <div id="1" className={`welcome-message${pageVisibility.home ? '-show' : ''}`}>
                  <div className="welcome">
                    <Link className="title" to="./">
                      Hello $user
                      <svg className="typing" width="32px" height="8px">
                        <rect fill="#fff" width="100%" height="100%"></rect>
                      </svg>
                    </Link> 
                  </div>
                  <span>
                    <p>
                      My name is Nathan McAree, I am a Big4 Cloud & DevOps consultant with an M.S. in Cybersecurity.
                      The purpose of this website is to provide practical examples of complex technical outlets, as well as store different projects I've done.
                    </p>
                    <p>
                      I am passionate about software development, data science, and cybersecurity. Check out some projects to the side to find out more.
                    </p>
                  </span>
                </div>
             
      

              <div className = "individual-project-wrapper">
                <div id="1" className={`individual-project${pageVisibility.python ? '-show' : ''}`}>
                  <header>Twitter Databreach Bot</header>
                  <span>
                  <img className="databreach" src={databreach} alt="seabornchart" />
                    
                      <h2> Goal </h2>
                      <p>
                        The goal of this bot was to create a Twitter page that could be a one stop shop for company breach news, faster than the outlets. Ideally it would provide keywords you could follow to keep up with.
                      </p>
                      <h2>Method</h2>
                      <p>
                        The tools used here were Python, Numpy, Pandas, Seaborn, REGEX and AWS.
                        This databreach bot was created using Twitter's API. I used REGEX to find stems of words like "breaching", "breached", etc. and summize them into "breach". 
                        After these tweets were scraped, I wanted to visualize the data using numpy and seaborn. 
                        The graph you see on these tweets is the culmination of what has been trending and using the keyword stems.
                        </p>
                      <h2>Infrastructure</h2>
                      <p>The bot was then deployed into an EC2 instance and would trigger automatically using cronjobs. 
                      I had further plans to tag and attach company names to the tweets to make an actual monitor for company breaches, but I delayed too long and now Twitter's API is no longer free.
                      If you would like to look more in detail about this project, check out the Github link here, or on the top right of the page!
                      </p>
                      <h2>My Thoughts</h2>
                      <p>I think this idea was pretty interesting. Actually, as you can see in the picture "Russian" shows up.
                      This bot was running from January 2022 - March 2022. This is the exact timeframe when the conflict began.
                      I think it works as an MVP but needs to be more fleshed out when I remake it later.
                    </p>
                    
                  </span>
                </div>
                <div id="2" className={`individual-project${pageVisibility.raspberry ? '-show' : ''}`}>
                  <header>Raspberry Pi, NAS and RTSP</header>
                  <span>
                    <h2>Open Media Vault Dashboard</h2>
                 
                    <img className="openmediavault" src={openmediavault} alt="openmediavaultdash" />
                      <h2> Goal </h2>
                      <p>
                        The goal here was to create an RTSP fed livestream from a security camera, and use my Raspberry Pi
                        as a Network-Area-Storage (NAS) server.
                      </p>
                      <h2>Method</h2>
                      <p>
                        To start off, I enabled RTSP on my IP Camera and hooked it up to a network I segmented for it.
                        I then installed Raspbian on my Raspberry Pi and installed OpenMediaVault (OMV). I was able to 
                        create a NAS specifically for my livestream, and whatever else I choose to put on it. 

                      
                      
                        </p>
                      <h2>Shinobi</h2>
                      <img className="shinobi" src={shinobi} alt="shinobidash" />  
                      <p>
                        Next, I installed Shinobi through a Docker image and was able to sync my RTSP feed into Shinobi. 
                        I fed Shinobi my absolute path for my storage, and set up timeframes to store or save data and events
                      </p>
                      <h2>Python Alerts</h2>
                      <p>
                        Next, I set my Shinobi to take screenshots every minute. I then wrote a script on my main PC in Python to evaluate
                        the current capture and take note of certain events. The Python script will search for color hexcodes of the street,
                        and based on the position they are within the view, will alert me. For instance, if a car moved out of my parking spot and 
                        my camera is viewing an empty parking spot.
                        </p>
                        <p>
                        I will admit it, this entire project was made so I can move my car into my parking spot.
                        I live on a busy street and don't like taking other people's spots. I was also very bored.
                    </p>
                    
                  </span>
                </div>


                
                <div id="3" className={`individual-project${pageVisibility.infrastructure ? '-show' : ''}`}>
                  <header>Securing Infrastructure</header>
                  <span>
                    <h2>Web Server </h2>
                  <p>
                      To create the website, I used a cloud provider and spun up and secured an Nginx server. After I implemented TLS certificates, 2FA, and proper security headers, the servers received an A+ on Qualys SSL Labs.
                      If you're interested in exactly how Qualys ranks, works, or are interested in testing the site click the link below.
                      </p>
                      <img className="qualys" src={qualys} alt="qualysrating" />
                      <button>
                      <a rel="noreferrer" target="_blank" href="https://www.ssllabs.com/ssltest/analyze.html?d=natemcaree.com">View report</a>
                    </button>
                 
                  <h2>MySQL Server</h2>
                  <img className="mysql" src={mysql} alt="mysqldatabaselist" />
                  <p> I wanted to work on my SQL and Tableau skills so I spun up a remote MySQL server to host my randomized data from Python on the same provider
                    as the web server. I could have used the same server, but that would go against some basic cybersecurity 
                    principles. I used similar tactics to secure this server, with the focus being on securing MySQL access,
                    data validation, and fuzzing.
                    Implementing proper SSH enabled me to properly define roles, privileges, and root segregation. 
                  </p>
                  
                  </span>
                </div>
                <div
                  id="4"
                  className={`individual-project${pageVisibility.webdev ? '-show' : ''}`}>
                  <header>React, Svelte, and APIs</header>
                  <span>
                      <h2>React</h2>
                      
                      <p>
                      This website was created using React, version controlled on Github, and implemented using remote servers (see more under "Cd /Infrastructure").
                      I used React to handle state management and build an auto-scroll to top functionality.
                      </p>
                      <p>
                      I created this website with the intention of making it one page to see how difficult it would be to scale and set up.
                      For a small scale project like this most problems revolved around state management and visibility.
                      If you would like to see how this was handled, view my Github linked above!
                      </p>
                      <h2>Web Portfolio</h2>
                      <p> 
                        For more websites I have built, please check out the following:
                        </p>
                        
                        <button> <a target ="blank" href="https://critellisautobody.com"> Critellisautobody.com </a></button>
                        <img className="critellis" src={critellis} alt="critellishome" />
                        <button> <a target ="blank" href="https://paradigmtherapynh.com"> Paradigmtherapynh.com </a></button>
                        <img className="paradigm" src={paradigm} alt="paradigmhome" />
                      
                      
                  </span>
                </div>
                <div id="5" className={`individual-project${pageVisibility.sql ? '-show' : ''}`}>
                  <header>SQL and Tableau</header>
                  <span>   
                    <h2> SQL </h2>
                    <img className="sql" src={sql} alt="sql" />
                    <p>
                      The goal of this SQL query was to perform as much work without Tableau as possible. 
                      Growing a large SQL foundation ensures that I can translate SQL queries to Python more effectively. This allows me more flexibility in what I can do within the database moving forward.
                      </p>
                      <p>
                      The randomized data I created had over 1 thousand job titles. To summarize this, I searched
                      the strings for keywords like "psychologist" in order to group the entries in a readable and digestable format.
                      I was able to take this query, and create a Tableau dashboard around the results.
                      </p>
                    <h2> Tableau </h2>
                  <img className="tableau" src={tableau} alt="tableaudashboard" />
                    <p>
                      Once I pushed the data into Tableau, I was able to use some simple table calculations to draw some interesting conclusions
                      as well as create an interactive environment. You can click the link below to see more about the findings.
                      </p>
                      <p>
                        *Keep in mind, this data was created in Python and is not representative of real world statistics!
                    </p>
                    <a rel="noreferrer" target="_blank" href="https://public.tableau.com/app/profile/nathan.mcaree/viz/PortfolioOccupationChildrenandVehicles/DashboardOccupationChildrenandVehicles">View Tableau Dashboard</a>
                  </span>
                </div>
                </div>
               
              </div>
              
             
             
            </main>


            
          </div>







          
        </div>
      </div>
    </>
  );
}

export default Home;
