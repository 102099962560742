import './App.css';
import Home from "./pages/Home"
import {Route, Routes} from "react-router-dom"
import "./fonts/Ubuntu-Light.ttf"
import "./fonts/Montserrat-Regular.ttf" 
import "./fonts/Montserrat-Light.ttf" 
import "./fonts/Montserrat-Thin.ttf" 


function App() {
 
  

  return (

    <div className="Main">
      <><Home /></> 
      </div> 

  );
}

export default App;

